import { graphql } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import CTASectionTrial from '../../components/CTASectionTrial/CTASectionTrial';
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import FeatureBlock from '../../components/FeatureBlock/FeatureBlock';
import PageSection from '../../components/PageSection/PageSection';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import { PageComponent } from '../../types/gatsby.types';
import { ColorPalette } from '../../utils/colors.utils';
import './single-feature.scss';

export const query = graphql`
query insightsFeatureQuery {
  singlePost: mdx(fileAbsolutePath: {regex: "/features\/insights/"}) {
    body
    frontmatter {
      title
      description
      overline
      featured_video {
        publicURL
      }
      featured_image {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      color
    }
    id
    slug
  },
  featuredVideo: file(relativePath: {eq: "videos/features/insights/single-feature-featured-video.mp4"}) {
    publicURL
  }
  filtersImage: file(relativePath: {eq: "images/features/insights/filters.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  dateRangeVideo: file(relativePath: {eq: "videos/features/insights/single-feature-date-range.mp4"}) {
    publicURL
  }
  tableImage: file(relativePath: {eq: "images/features/insights/table.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }

}
`

const SingleFeature: PageComponent = props => {

  // const featuredImage = getImage(props.data.singlePost.frontmatter.featured_image

  return <Observer children={() => (
    <PageTemplate 
      {...props} // always include
      className="SingleFeature SingleFeatureInsights"
      title="Insights"
      description="We want you to see exactly how trackster.io is improving your company’s performance. Gantt Charts, Kanban Boards and Calendars visually display your work. Our automatic reporting functionality can clearly display your productivity in just a few clicks.
      "
      // description={props.data.singlePost.frontmatter.description}
      // title={props.data.singlePost.frontmatter.title}
      // description={props.data.singlePost.frontmatter.description}
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >

      {/* <DefaultPageHeader
        id="FeaturesPageHeader"
        title="Affordable and Effortless Project Management"
        overline="All features"
        // subtitle="Check why trackster is the best solution for your business"
        children={
          <div>
            <p>Trackster is a simple to use, low cost all-in-one time tracking and project management tool for high performing projects.</p>
          </div>
        }
      ></DefaultPageHeader> */}

      <DefaultPageHeader
        id="SingleFeatureInsightsHeader"
        className="SingularPageHeader" // Singular is the WP naming for both default pages + posts + custom post types (as a fallback)
        // title={props.data.singlePost.frontmatter.title}
        overline="trackster feature"
        title="Easily Visualise Performance with Insights"
        // subtitle="Visualise your entire team's performance with just a couple of clicks"
        children={
          <div>
            <p>
              We want you to see exactly how trackster.io is improving your company’s performance. Gantt Charts, Kanban Boards and Calendars visually display your work. Our automatic reporting functionality can clearly display your productivity in just a few clicks.
            </p>
          </div>
        }
      ></DefaultPageHeader>

      <PageSection id="SingleFeatureArticle SingleBlogArticle">

        {
          props.data.featuredVideo ? 
          // We need the wrapping div to be able to style the video appropriately
          <div className="FeatureBlockVideo" id="SingleFeatureFeaturedVideo"> 
            <video autoPlay muted loop 
            // poster={image.publicURL}
            // TODO: Edge cases (no image / no video, etc)
            >
              <source src={props.data.featuredVideo.publicURL} type="video/mp4"/>
            </video>
          </div>
          : null
          }


        <article className="SingleBlogArticleContent SingleFeatureArticleContent">
          {/* <p className='SingleFeatureDescription'>
            {props.data.singlePost.frontmatter.description}
          </p> */}

          <FeatureBlock
          id="SingleFeatureFilters"
          title="Filter everything that can be filtered, anytime "
          image={props.data.filtersImage}
          // image={props.data.liveActivityImage}
          // overline="Filter everything"
          >
            <p>
              All options for filtering your insights are available to you right out the box. Use filter per workspace, project, user and even per task, so you can see exactly the data that is relevant to you
            </p>
          </FeatureBlock>

          <FeatureBlock
          id="SingleFeatureDateRange"
          title="See the activity for the time range you need, forever"
          // image={props.data.singlePost.frontmatter.featured_image}
          videoFilePath={props.data.dateRangeVideo.publicURL}
          // overline="Filter everything"
          >
            <p>
              Enter a custom time range or quickly select any of our presets to analyse the insights of your team’s activity
            </p>

          </FeatureBlock>

          <FeatureBlock
          id="SingleFeatureTable"
          title="Find the data you need and when ready, export it"
          // image={props.data.singlePost.frontmatter.featured_image}
          image={props.data.tableImage}
          // overline="Filter everything"
          >
            <p>
              See the full output of your filter in the interactive Insight table and if you want, export it and share with those who need it!
            </p>
          </FeatureBlock>

          {/* <MDXRenderer>
            {props.data.singlePost.body}
          </MDXRenderer> */}

        </article>


      </PageSection>

      {/* <PageSection id="BlogEntryListSection">
        <h2 className="BlogEntryListSectionHeading">Latest articles</h2>
        <BlogEntryList
        id=""
        allMdx={props.data.allPosts}
        ></BlogEntryList>s
      </PageSection> */}

      <CTASectionTrial
      title="Want to test these and many more features live?"
      />

    </PageTemplate>
  )} />
}

export default SingleFeature;