import { Observer } from 'mobx-react-lite';
import React from 'react';
import Spacer from '../../components/Spacer/Spacer';
import { ImageWithMetaInfo } from '../../types/app.types';
import { ImageDataLike } from '../../types/gatsby.types';
import joinClassName from '../../utils/className.utils';
import { ColorName } from '../../utils/colors.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import GatsbyImageAdaptor from '../GatsbyImageAdaptor/GatsbyImageAdaptor';
import PageSection from '../PageSection/PageSection';
import './FeatureBlock.scss';

type FeatureBlockProps = {
  id: string, // CSS/HTML #id
  className?: string
  title: string | any,
  overline?: string,
  image?: ImageWithMetaInfo | ImageDataLike | string,
  color?: ColorName;
  videoFilePath?: string;
  link?: string;
  // order?: number
}

const FeatureBlock: React.FC<FeatureBlockProps> = props => {
  const image = props.image;
  // console.log(image);

  return <Observer children={() => (
    <PageSection
    // key={props.id}
    id={props.id}
    className={joinClassName('FeatureBlock', props.color ? 'FeatureBlock--'+props.color : null, props.className)}
    observeVisibility
    >
      <div className='FeatureBlockInner'>
        <article className="FeatureBlockContent">
          <main>
          {props.overline ? 
          <span className="FeatureBlockOverline">{props.overline}</span>
          : null
          }
          <h2 className="FeatureBlockTitle">{props.title}</h2>
          </main>
          {props.children? 
          <aside className="FeatureBlockChildren">
            <div className='FeatureBlockChildrenContent'>
              {props.children}
            </div>
            {props.link? 
              <AnimatedLink className="FeatureBlockLink" to={props.link} title={props.title}>
              Learn more
            </AnimatedLink> :
            null
            }

          </aside>
          : null }
        </article>
        {
          props.image || props.videoFilePath ? 
          <Spacer size="lg" />          
          : null
        }
        
        {/* {console.log(props.image)} */}
        { 
          props.image ? 
            // TODO: Handle image for poster (For <video> ) if publicURL
            <GatsbyImageAdaptor image={props.image} />
            
            // <StaticImage src={props.image} alt="">
            // </StaticImage>

            // <GatsbyImage
            // image={props.image}
            // alt=""
            // ></GatsbyImage>
          : null
        }
        
        {
          props.videoFilePath ? 
          // We need the wrapping div to be able to style the video appropriately
          <div className="FeatureBlockVideo"> 
            <video autoPlay muted loop 
            // poster={image.publicURL}
            // TODO: Edge cases (no image / no video, etc)
            >
              <source src={props.videoFilePath} type="video/mp4"/>
            </video>
          </div>
          : null
        }

      </div>
      
    </PageSection>
  )} />
}

export default FeatureBlock;